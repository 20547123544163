import gql from 'graphql-tag';

export const GET_OBJECT = gql`
query getObject($id: Int!) {
    object(id: $id) {
        id
        location
        label
        rocLabel {
            path
        }
        rocLocation
        sourceLabel {
            path
        }
        sourceLocation
        manualLabel {
            path
        }
        manualLocation
        extra
        image {
            id
            s3
        }
    }
}
`;

export const DELETE_OBJECT = gql`
mutation deleteObject($objectId: Int!) {
    deleteObject(input: { id: $objectId }) {
        object {
            id
        }
    }
}
`;

export const CREATE_OBJECT = gql`
mutation createObject($input: ObjectInput!) {
    createObject(input: { object: $input }) {
        object {
            id
            location
        }
    }
}
`;

export const UPDATE_OBJECT = gql`
mutation updateObject($id: Int!, $patch: ObjectPatch!) {
    updateObject(input: { patch: $patch, id: $id}) {
        object {
            id
        }
    }
}
`;

export const GET_OBJECTS_BY_IMAGE = gql`
query getObjectsByImage($id: Int!, $filter: ObjectFilter) {
    image(id: $id) {
        id
        s3
        objects(filter: $filter) {
            nodes {
                id
                location
                locationStatus
                rocLabel {
                    path
                }
                rocLocation
                sourceLabel {
                    path
                }
                sourceLocation
                manualLabel {
                    path
                }
                manualLocation
                extra
            }
        }
        extra
    }
 }
 `;

export const GET_OBJECT_LABELS = gql`
query getObjectLabels($filter: ObjectLabelFilter) {
    objectLabels(filter: $filter) {
        nodes {
            id
            path
            displayName
        }
    }
}
`;

export const CREATE_EXHAUSTIVE_LABEL = gql`
mutation createExhaustiveLabel($input: CreateUpdateExhaustiveLabelInput!) {
    createUpdateExhaustiveLabel(input: $input) {
        exhaustiveLabel {
            imageId
            labelId
            status
        }
    }
}
`;