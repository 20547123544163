<template>
    <div class="navbar-item">
        <button class="button is-black" @click="logout">
            Logout
        </button>
    </div>
</template>

<script setup>
    import { useAuthStore } from '@/stores/auth';
    import { useBrowserStore } from '@/stores/browser';
    import { useRouter } from "vue-router";

    const authStore = useAuthStore();
    const browserStore = useBrowserStore();
    const router = useRouter();

    const logout = async () => {
        browserStore.$reset();
        await authStore.logout();
        window.location.reload(true);
        router.push("/login");
    };

</script>